<template>
  <div
    v-if="canEdit"
    :class="dropdownClass"
    @click="toggleDropdown"
  >
    <div class="dropdown-trigger">
      <button class="button" aria-haspopup="true" aria-controls="dropdown-menu">
        <span class="icon is-small">
          <i class="fas fa-cog" aria-hidden="true"></i>
        </span>
        <span class="icon is-small">
          <i class="fas fa-angle-down" aria-hidden="true"></i>
        </span>
      </button>
    </div>
    <div class="dropdown-menu" id="dropdown-menu" role="menu">
      <div class="dropdown-content">
        <a
          class="dropdown-item"
          @click="edit"
        >
          Edit
        </a>
        <a
          class="dropdown-item"
          @click="printQr"
        >
          Print QR
        </a>
      </div>
    </div>
  </div>

</template>

<script>
import {AuthError, NetworkError} from "@/errors";

export default {
  props: {
    deal: {
      type: Object,
      required: true
    },
    locationId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dropdownClass: "dropdown"
    }
  },
  computed: {
    isAdmin: function() {
      return this.$store.getters.isAdmin;
    },
    currentBusiness: function() {
      return this.$store.getters.currentBusiness;
    },
    canEdit: function() {
      return ((this.$store.getters.canEdit || this.isAdmin)
        && typeof this.deal.slug !== 'undefined'
        && this.deal.business.slug === this.currentBusiness.slug
      ) ? true : false;
    }
  },
  methods: {
    toggleDropdown() {
      this.dropdownClass = (this.dropdownClass == "dropdown") ? "dropdown is-active" : "dropdown"
    },
    go(path) {
      // the edit & printqr views expect currentDeal to be already set
      this.$store.dispatch("getDeal", this.deal.slug)
      .then(() => {
        this.$router.push(path)
      })
      .catch(error => {
        if (error instanceof AuthError || error instanceof NetworkError) {
          throw error
        } else {
          console.log(error)
        }
      })
    },
    edit() {
      const path = `/deal/${this.deal.business.slug}/${this.deal.slug}/l/${this.locationId}/edit`
      this.go(path)
    },
    printQr() {
      const path = `/deal/${this.deal.business.slug}/${this.deal.slug}/l/${this.locationId}/print-qr`
      this.go(path)
    },
  }
}
</script>
