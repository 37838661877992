<template>
  <div>
    <h2 class="title">
      <span v-if="slug == 'new'">New deal</span>
      <span v-else>Update deal</span>
    </h2>
    <div class="columns">
      <div class="column">
          <form @submit.prevent="onSubmit">
  
            <div
              v-if="slug != 'new'"
              class="field"
            >
              <label class="label">Deal status</label>
              <div 
                v-if="canActivateDeal"
                class="control">
                <div class="select">
                  <select
                    v-model="deal.is_active"
                    @change="detectChange()"
                  >
                    <option 
                      v-for="opt in status_options"
                      :key="opt.value"
                      :value="opt.value"
                    >
                      {{ opt.label || 'No label' }}  
                    </option>
                  </select>
                </div>
              </div>
              <div
                v-else
              >
                Not active. You must maintain a minimum balance to activate deal.
              </div>
            </div>

             <div class="field">
              <label class="label">Category</label>
              <div 
                class="control">
                <div class="select">
                  <select
                    v-model="deal.category"
                    @change="detectChange()"
                  >
                    <option 
                      v-for="opt in category_options"
                      :key="opt.value"
                      :value="opt.value"
                    >
                      {{ opt.label || 'No label' }}  
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <BaseInput
              label="Minimum spend"
              type="number"
              v-model.trim="$v.minSpendDollars.$model"
              :validation="$v.minSpendDollars"
              @input="detectChange()"
            />
            <BaseInput
              label="Amount rewarded"
              type="number"
              v-model.trim="$v.awardValueDollars.$model"
              :validation="$v.awardValueDollars"
              @input="detectChange()"
            />
            <BaseInput
              label="Description (optional - text appears instead of minimum spend amount)"
              v-model.trim="deal.optional_description"
              @input="detectChange()"
            />
            <div class="field">
              <label 
                class="checkbox is-block" 
              >
                <input 
                  type="checkbox"
                  v-model="deal.is_repeatable"
                  @change="detectChange()"
                >
                more than one per customers
              </label>
              <label 
                class="checkbox is-block"
              >
                <input 
                  type="checkbox"
                  v-model="deal.is_condition"
                  @change="detectChange()"
                >
                cannot be combined with other offers
              </label>
            </div>
            
            <div style="margin-bottom:20px;">
              <BaseUpload
                v-if="slug == 'new'"
                label="Upload image"
              />
              <BaseUpload
                v-else
                label="Update image"
              />
            </div>

            <div
              v-if="slug == 'new'"
              class="field"
            >
              <label class="label">Deal status</label>
              <div 
                v-if="canActivateDeal"
                class="control">
                <div class="select">
                  <select
                    v-model="deal.is_active"
                    @change="detectChange()"
                  >
                    <option 
                      v-for="opt in status_options"
                      :key="opt.value"
                      :value="opt.value"
                    >
                      {{ opt.label || 'No label' }}  
                    </option>
                  </select>
                </div>
              </div>
              <div
                v-else
              >
                Not active. You must maintain a minimum balance to activate deal.
              </div>
            </div>


            <div
             v-if="showInsufficientBalance"
             class="mb-4"
            >
              <p class="has-text-danger">Your balance is {{ USD(currentBusiness.balance).format() }}. </p>
              <p>Please 
                <a
                  @click="billigLink()"
                >
                  purchase more credits
                </a>
                in order to publish this deal
              </p>
            </div>
            <BaseSubmit
              label="Save"
              :disabled="$v.$invalid || noChanges"
              :submitting="submitting"
              :clearShow="true"
            />
          </form>
          <div 
            style="margin-top:20px;">
            <a
              v-if="slug == 'new'"
              class="is-size-7 has-text-grey"
              @click="cancel()"
            >
              &larr; cancel
            </a>
            <a
              v-else
              class="is-size-7 has-text-grey"
              @click="go()"
            >
              &larr; back to deal
            </a>
          </div>
      </div>
      <div class="column">
        <DealCard 
          :deal="deal" 
          :isLink="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {AuthError, NetworkError} from "@/errors";
import { integer, minValue, maxValue, required } from 'vuelidate/lib/validators';
import BaseInput from "@/components/form/BaseInput.vue";
import BaseUpload from "@/components/form/BaseUpload.vue";
import BaseSubmit from "@/components/form/BaseSubmit.vue";
import DealCard from "@/components/DealCard.vue";

export default {
  props: {
    orgSlug: {
      type: String,
      required: true
    },
    slug: {
      type: String,
      required: true
    },
    locationId: {
      type: String,
      required: true
    }
  },
  components: {
    BaseInput,
    BaseUpload,
    BaseSubmit,
    DealCard
  },
   data() {
    return {
      loading: false,
      noChanges: true,
      error: null,
      desc_text: '',
      submitting: false,
      showInsufficientBalance: true,
      deal: {
        min_spend: '',
        award_value: '',
        optional_description: ''
      },
      status_options: [
        {
          label: "Active",
          value: true
        },
        {
          label: "Inactive",
          value: false
        }
      ],
      category_options: [
        {
          label: "Thing to do",
          value: "todo"
        },
        {
          label: "Shop",
          value: "shop"
        },
        {
          label: "Eat and drink",
          value: "eat"
        },
        {
          label: "Service",
          value: "services"
        },
        {
          label: "Stay",
          value: "stay"
        },
      ]
    }
  },
  validations: {
    minSpendDollars: { required, integer, minValue: minValue(1) },
    awardValueDollars: { required, integer, minValue: minValue(1), maxValue: maxValue(1000) }
  },
  computed: {
    currentUser: function() {
      return this.$store.getters.currentUser;     
    },
    currentBusiness: function() {
      return this.$store.getters.currentBusiness;     
    },
    currentDeal: function() {
      return this.$store.getters.currentDeal;     
    },
    isAdmin: function() {
      return this.$store.getters.isAdmin;
    },
    canActivateDeal: function() {
      return (this.currentBusiness.balance > this.deal.award_value) ? true : false
    },
    minSpendDollars: {
      get: function() {
        return Math.round(this.deal.min_spend / 100)
      },
      set: function(newValue) {
        this.deal.min_spend = Math.round(newValue) * 100
      }
    },
    awardValueDollars: {
      get: function() {
        return Math.round(this.deal.award_value / 100)
      },
      set: function(newValue) {
        this.deal.award_value = Math.round(newValue) * 100
      }
    }
  },
  mounted() {
    if ((this.currentBusiness
      && this.currentBusiness.slug
      && this.currentDeal
      && this.currentDeal.slug
      && this.currentBusiness.slug == this.currentDeal.business.slug)
    ) {
      this.deal = this.currentDeal
      this.insufficientBalance()
    } else if (
        this.currentBusiness && 
        this.currentBusiness.slug && 
        this.slug == 'new'
    ){
      this.deal = {
        is_active: true,
        category: '',
        min_spend: '',
        award_value: '',
        description: '',
        image: '',
        optional_description: '',
        is_repeatable: true,
        is_condition: true,
        business: {
          name: this.currentBusiness.name
        }
      }
    } else {
      this.$router.go(-1)
    } 
  },
  methods: {
    detectChange() {
      this.noChanges = false
      this.updateDescription()
      this.insufficientBalance()
    },
    reset() {
      this.noChanges = true
      this.$router.go()
    },
    billigLink() {
      const path = "/settings-billing"
      this.$router.push(path)
    },
    updateDescription() {
      if (this.minSpendDollars > 0 && this.awardValueDollars > 0) {
        this.deal.description = this.deal.optional_description ? this.deal.optional_description : `Spend $${this.minSpendDollars}`
        this.deal.description += `. Earn $${this.awardValueDollars}`
      }
    },
    insufficientBalance() {
      if (this.currentBusiness.balance <= this.deal.award_value) {
        this.showInsufficientBalance = true
        this.noChanges = true
      } else {
        this.showInsufficientBalance = false
      }
    },
    go() {
      const path = `/deal/${this.deal.business.slug}/${this.deal.slug}/l/${this.locationId}`
      this.$router.push(path)
    },
    cancel() {
      this.$router.go(-1)
    },
    uploadImg(image_url) {
      this.deal.image = image_url
      this.detectChange()
    },
    onSubmit() {
      this.submitting = true;
      const params = {
        user_slug: this.currentUser.slug,
        org_slug: this.currentBusiness.slug,
        slug: this.currentDeal.slug,
        deal: {
          min_spend: this.deal.min_spend,
          award_value: this.deal.award_value,
          optional_description: this.deal.optional_description,
          is_repeatable: this.deal.is_repeatable,
          is_condition: this.deal.is_condition,
          is_active: this.deal.is_active,
          category: this.deal.category,
          image: this.deal.image
        },  
      }
      if (this.currentDeal.slug != undefined) {
        this.updateDeal(params)
      } else {
        this.newDeal(params)
      }
    },
    updateDeal(params) {
     this.$store.dispatch("updateDeal", params)
        .then(() => {
          this.deal = this.currentDeal
          this.submitting = false
          this.noChanges = true
        })
        .catch(error => {
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            this.error = error
          }
        })
    },
    newDeal(params) {
      this.$store.dispatch("createDeal", params)
        .then(() => {
          this.deal = this.currentDeal
          this.submitting = false
          this.$router.go(-1)
        })
        .catch(error => {
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            this.error = error
          }
        })
    }
  }
}
</script>
