<template>
  <div 
    class="card"
    style="cursor:pointer"
  >
    <span
      v-if="deal.currentUserStatus != null" 
      class="ribbon" 
      :data-ribbon="deal.currentUserStatus" 
      :title="deal.currentUserStatus"
    >
      {{ deal.currentUserStatus }}
    </span>
    
    <div class="card-image">
      <figure class="image is-2by1">
        <img :src="deal.image" @click="go">

        <nav class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <p
                v-if="!deal.is_active && !previewInactive"
                class="tag is-danger"
              >
                Inactive
              </p>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <DealEditMenu
                v-if="isLink"
                :deal="deal"
                :locationId="deal.location_id.toString()"
              />
            </div>
          </div>
        </nav>
      </figure>
    </div>
    <div
      :class="cardClass"
      @click="go"
    >
      <div class="media" style="padding:0px;margin:0px;">
        <div class="media-left">
          <figure class="image is-24x24">
            <CategoryImage :name="deal.category" width="24" height="24" />
          </figure>
        </div>
        <div class="media-content">
          <p class="title has-text-white is-5">
            {{ businessName }}
          </p>
        </div>
        <div 
          v-if="showDistance"
          class="media-right">
          <span>
            {{ deal.distance }}&nbsp;mi
          </span>
        </div>
      </div>
      <div style="margin-left:40px;">
        {{ deal.description }} 
        <span 
          v-if="otherDealsCount > 0"
        >
          ({{ otherDealsCount }} other deals)
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryImage from "@/components/CategoryImage.vue";
import DealEditMenu from "@/components/DealEditMenu.vue";

export default {
  props: {
    deal: {
      type: Object,
      required: true
    },
    showDistance: {
      type: Boolean,
      default: false
    },
    isLink: {
      type: Boolean,
      default: true
    },
    previewInactive: {
      type: Boolean,
      default: false
    },
  },
  components: {
    CategoryImage,
    DealEditMenu
  },
  data() {
    return {
      editMenuClass: "dropdown"
    }
  },
  computed: {
    businessName: function() {
      if (this.deal && this.deal.business && this.deal.business.name) {
        return this.deal.business.name
      } else {
        return null
      }
    },
    otherDealsCount: function() {
      if (this.deal && this.deal.otherDeals) {
        return this.deal.otherDeals.length
      }
      return 0
    },
    cardClass: function() {
      return `card-content ${this.deal.category} has-text-white`
    }
  },
  methods: {
    go() {
      if (this.isLink == true) {
        const path = `/deal/${this.deal.business.slug}/${this.deal.slug}/l/${this.deal.location_id}`
        this.$router.push(path)
      }
    }
  }
}
</script>

<style scoped>
.level {
  padding: 10px;
  z-index: 1;
}
.tag {
  z-index: 1;
}

.shop {
  background-color: rgb(165,63,151);
}
.todo {
  background-color: rgb(0,140,154);
}
.eat {
  background-color: rgb(215,25,32);
}
.stay {
  background-color: rgb(92,45,145);
}
.services {
  background-color: rgb(192,72,25);
}


.ribbon {
  width: 12.1em;
  height: 12.1em;
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  z-index: 2;
  pointer-events: none;
  font-size: 13px;
  text-decoration: none;
  text-indent: -999999px;
}
.ribbon.fixed {
  position: fixed;
}
.ribbon:hover, .ribbon:active {
  background-color: rgba(0, 0, 0, 0.0);
}
.ribbon:before, .ribbon:after {
  /* The right and left classes determine the side we attach our banner to */
  position: absolute;
  display: block;
  width: 15.38em;
  height: 1.54em;
  top: 3.23em;
  right: -3.23em;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.ribbon:before {
  content: "";
  /* Add a bit of padding to give some substance outside the "stitching" */
  padding: .38em 0;
  /* Set the base colour */
  background-color: rgb(23, 0, 170);
  /* Set a gradient: transparent black at the top to almost-transparent black at the bottom */
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.15)));
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15));
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15));
  background-image: -ms-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15));
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15));
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15));
  /* Add a drop shadow */
  -webkit-box-shadow: 0 .15em .23em 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 .15em .23em 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 .15em .23em 0 rgba(0, 0, 0, 0.5);
  pointer-events: auto;
}
.ribbon:after {
  /* Set the text from the data-ribbon attribute */
  content: attr(data-ribbon);
  /* Set the text properties */
  color: #fff;
  font: 700 1em "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.54em;
  text-decoration: none;
  text-shadow: 0 -.08em rgba(0, 0, 0, 0.5);
  text-align: center;
  text-indent: 0;
  /* Set the layout properties */
  padding: .15em 0;
  margin: .15em 0;
  /* Add "stitching" effect */
  border-width: .08em 0;
  border-style: dotted;
  border-color: #fff;
  border-color: rgba(255, 255, 255, 0.7);
}
.ribbon.left-top, .ribbon.left-bottom {
  right: auto;
  left: 0;
}
.ribbon.left-bottom, .ribbon.right-bottom {
  top: auto;
  bottom: 0;
}
.ribbon.left-top:before, .ribbon.left-top:after, .ribbon.left-bottom:before, .ribbon.left-bottom:after {
  right: auto;
  left: -3.23em;
}
.ribbon.left-bottom:before, .ribbon.left-bottom:after, .ribbon.right-bottom:before, .ribbon.right-bottom:after {
  top: auto;
  bottom: 3.23em;
}
.ribbon.left-top:before, .ribbon.left-top:after, .ribbon.right-bottom:before, .ribbon.right-bottom:after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
</style>
