<template>
  <img :src="svg" :width="width" :height="height" :class="name" />
</template>

<script>
export default {
  props: ["name", "width", "height"],
  computed: {
    svg: function() {
      return "/badges/" + this.name + ".svg";
    },
  }
};
</script>

<style scoped>
img {
  padding: 5px;
}
.shop {
  background-color: rgb(165,63,151);
}
.todo {
  background-color: rgb(0,140,154);
}
.eat {
  background-color: rgb(215,25,32);
}
.stay {
  background-color: rgb(92,45,145);
}
.services {
  background-color: rgb(192,72,25);
}
</style>